import {
  forwardRef,
  HTMLAttributes,
  useCallback,
  useMemo,
  useState,
} from 'react'
import { Avatar } from '@nextui-org/avatar'

import { cn, getInitials } from '@/utils'

import DndHandle from './DndHandle'
import UITreeInput from './Input'
import {
  MoreHorizontalCircleIcon,
  PencilEditIcon,
  TrashCanIcon,
  ChevronDownIcon,
} from '../icons'
import { UIDropdown } from '../Dropdown'
import { UIButton } from '../Button'
import { UITooltip } from '../Tooltip'

export interface UITreeItemProps
  extends Omit<HTMLAttributes<HTMLLIElement>, 'id'> {
  clone?: boolean
  collapsed?: boolean
  isEditable?: boolean
  isSortable?: boolean
  isCompact?: boolean
  depth: number
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleProps?: any
  indentationWidth: number
  label: string
  isDragging?: boolean
  showMenu?: boolean
  disableInteraction?: boolean
  currentPath?: string
  as?: React.ElementType
  href?: string
  Icon?: React.ElementType
  ExpandedIcon?: React.ElementType
  isDisabled?: boolean
  onCollapse?(): void
  onRemove?(): void
  onRename?(value: string): void
  wrapperRef?(node: HTMLLIElement): void
}

const UITreeItem = forwardRef<HTMLDivElement, UITreeItemProps>(
  (
    {
      clone,
      depth,
      handleProps,
      isDragging,
      indentationWidth,
      disableInteraction,
      collapsed,
      onCollapse,
      onRemove,
      onRename,
      Icon,
      ExpandedIcon,
      style,
      label,
      currentPath,
      as,
      href,
      wrapperRef,
      isEditable,
      isCompact,
      isSortable,
      showMenu = false,
      isDisabled,
      ...props
    },
    ref,
  ) => {
    const [isEditing, setIsEditing] = useState(false)

    const toggleEditing = useCallback(() => setIsEditing(prev => !prev), [])

    const TitleComponent = as ?? 'div'

    const isLink = !!href

    const isSelected = isLink && currentPath === href

    const isSubItem = depth > 0

    const ItemIcon = !isSubItem
      ? collapsed
        ? Icon
        : ExpandedIcon || Icon
      : null

    const options = useMemo(() => {
      if (!isEditable || !showMenu) return []

      return [
        {
          key: 'default',
          showDivider: true,
          items: [
            {
              key: 'rename',
              children: 'Rename',
              ariaLabel: 'Rename',
              startContent: <PencilEditIcon className="h-4 w-4" />,
              onClick: toggleEditing,
            },
          ],
        },
        {
          key: 'destructive',
          items: [
            {
              key: 'remove',
              children: 'Remove',
              ariaLabel: 'Remove',
              startContent: <TrashCanIcon className="h-4 w-4 text-danger" />,
              onClick: onRemove,
            },
          ],
        },
      ]
    }, [toggleEditing, isEditable, onRemove, showMenu])

    const onClickHandler = useCallback(
      (e: React.MouseEvent<HTMLDivElement>) => {
        if (isSelected || !isLink) {
          isSelected && e.preventDefault()
          onCollapse?.()
        }
      },
      [isSelected, isLink, onCollapse],
    )

    if (isDragging && isSortable)
      return (
        <li
          ref={wrapperRef}
          className="h-0.5 list-none relative rounded-lg"
          style={
            {
              '--favorite-spacing': `${indentationWidth * depth}px`,
            } as React.CSSProperties
          }
          {...props}
        >
          <div
            ref={ref}
            style={{ ...style, paddingLeft: 'var(--favorite-spacing)' }}
          >
            <div className="bg-primary-400 h-0.5 rounded-lg opacity-70" />
          </div>
        </li>
      )

    if (isCompact) {
      return (
        <li
          className={cn(
            'group/core relative list-none flex items-center overflow-hidden',
            'w-11 h-11 p-0 justify-center',
            disableInteraction && 'pointer-events-none',
            isDisabled && 'opacity-50 pointer-events-none',
          )}
          ref={wrapperRef}
          {...props}
        >
          <TitleComponent
            ref={ref}
            role="button"
            href={href}
            style={style}
            data-selected={isSelected}
            className="ui group/item flex justify-center items-center font-medium w-full h-full text-default-500 hover:text-default-foreground data-[selected=true]:text-primary-500"
            onClick={onClickHandler}
          >
            <UITooltip content={label} placement="right">
              <div className="w-full h-full p-1 flex items-center justify-center">
                {isSubItem || !ItemIcon ? (
                  <Avatar
                    isBordered
                    className="w-6 h-6 text-tiny"
                    name={label}
                    getInitials={getInitials}
                    classNames={{
                      base: cn(
                        'bg-transparent transition-colors text-default-500 ring-1',
                        !isSelected
                          ? 'data-[hover=true]:ring-foreground data-[hover=true]:text-default-foreground'
                          : 'ring-primary-400 text-primary-500',
                      ),
                    }}
                  />
                ) : (
                  <ItemIcon
                    className={cn(
                      'h-6 w-6 text-inherit',
                      isSelected && 'text-primary',
                    )}
                  />
                )}
              </div>
            </UITooltip>

            {onCollapse && (
              <ChevronDownIcon
                className={cn(
                  'w-4 h-4 absolute bottom-0 -right-1 text-default-500 group-hover/core:text-default-foreground transition-all duration-200 ease-linear',
                  collapsed && 'rotate-90',
                )}
              />
            )}
          </TitleComponent>
        </li>
      )
    }

    return (
      <li
        className={cn(
          'group/core relative h-8 list-none flex items-center overflow-hidden',
          isEditing ? 'pl-2' : 'pl-3',
          !clone &&
            isSubItem &&
            'before:content-[""] before:absolute before:bg-default-200 before:w-px before:top-0 before:bottom-0 before:left-5 before:ml-px before:transition-all before:duration-200 before:ease-linear',
          !clone && isSubItem && isSelected && 'before:bg-primary',
          isDisabled && 'opacity-50 pointer-events-none',
          disableInteraction && 'pointer-events-none',
        )}
        ref={wrapperRef}
        style={
          {
            '--favorite-spacing': `${(indentationWidth + (isSubItem ? 5 : 0)) * depth}px`,
          } as React.CSSProperties
        }
        {...props}
      >
        {isSortable && !isEditing && (
          <DndHandle
            {...handleProps}
            className="hidden absolute h-4 w-4 -left-[5px] top-1/2 -translate-y-1/2 group-hover/core:inline-block items-center text-default-300 hover:text-default-foreground cursor-move z-20"
          />
        )}

        <TitleComponent
          ref={ref}
          role="button"
          href={href}
          style={{
            ...style,
            paddingLeft: isCompact ? 0 : 'var(--favorite-spacing)',
          }}
          data-selected={isSelected}
          className={cn(
            'ui group/item flex items-center font-medium w-full h-full text-default-500 data-[selected=true]:text-primary-500',
            isLink && 'hover:text-default-foreground',
            isCompact ? 'justify-center' : 'gap-x-0.5',
          )}
          onClick={onClickHandler}
        >
          {ItemIcon && !isEditing && (
            <ItemIcon
              className={cn(
                'h-5 w-5 mr-1 text-inherit',
                isSelected && 'text-primary',
              )}
            />
          )}

          {isEditing ? (
            <UITreeInput
              startContent={
                <PencilEditIcon className="h-[22px] w-[22px] mr-1 ml-0.5 text-default-500" />
              }
              inputClassName="!px-0.5"
              inputWrapperClassName="!px-0.5"
              defaultValue={label}
              placeholder="Enter name..."
              onClose={toggleEditing}
              onPressEnter={onRename}
            />
          ) : (
            <span className="flex-1 truncate text-xs text-inherit select-none">
              {label}
            </span>
          )}

          {!clone && (
            <div
              className={cn(
                'flex gap-x-1 items-center text-xs transition-all ml-1',
              )}
            >
              {isEditable && !isEditing && (
                <div className="hidden items-center h-4 group-hover/core:flex z-10">
                  {isSubItem || !showMenu ? (
                    <UITooltip content="Remove" placement="right">
                      <UIButton
                        isIconOnly
                        size="sm"
                        variant="light"
                        className="min-w-6 h-6 w-6 text-danger-500 hover:text-danger-400"
                        onClick={e => e.preventDefault()}
                        onPress={onRemove}
                      >
                        <TrashCanIcon className="h-3.5 w-3.5 text-danger" />
                      </UIButton>
                    </UITooltip>
                  ) : (
                    <UIDropdown
                      hideSelectedIcon
                      selectionMode="none"
                      aria-label={`${label} options`}
                      variant="light"
                      dropdownProps={{
                        placement: 'bottom-end',
                      }}
                      triggerContent={
                        <UIButton
                          isIconOnly
                          size="sm"
                          variant="light"
                          className="min-w-6 h-6 w-6 text-default-500 hover:text-default-foreground"
                          onClick={e => e.preventDefault()}
                        >
                          <MoreHorizontalCircleIcon className="h-3 w-3 text-inherit" />
                        </UIButton>
                      }
                      sections={options}
                    />
                  )}
                </div>
              )}

              {onCollapse && !isEditing && (
                <ChevronDownIcon
                  className={cn(
                    'w-4 h-4 text-default-500 group-hover/core:text-default-foreground transition-all duration-200 ease-linear',
                    collapsed && 'rotate-90',
                    'mr-1',
                  )}
                />
              )}
            </div>
          )}
        </TitleComponent>
      </li>
    )
  },
)

UITreeItem.displayName = 'Docsum.UITreeItem'

export default UITreeItem

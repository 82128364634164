import { ApiClientError, ApiClientKnownErrorData } from '@/services/api/client'

export const getKnownError = (rawError: unknown) => {
  if (!isKnownError(rawError)) return null

  return rawError
}

export const isKnownError = (
  data: unknown,
): data is ApiClientKnownErrorData => {
  return (
    data !== null &&
    typeof data === 'object' &&
    'code' in data &&
    'detail' in data
  )
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const handleErrorByConsumer = (
  onKnownError?: (error: string) => void,
) => ({
  meta: {
    throwKnownErrorAsGlobal: false,
  },
  onError: (error: unknown) => {
    const { knownError } = getParsedError(error)
    if (knownError && onKnownError) onKnownError(knownError.detail)
  },
})

export const getParsedError = (rawError: unknown) => {
  const apiError = rawError as ApiClientError

  const { code = 'UNKNOWN_ERROR', message = 'An unexpected error occurred' } =
    apiError

  const { status: responseStatus = 500, data } = apiError.response || {}

  const knownError = getKnownError(data)

  return {
    code,
    message,
    responseStatus,
    knownError,
  }
}

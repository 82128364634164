import React, { CSSProperties } from 'react'
import type { UniqueIdentifier } from '@dnd-kit/core'
import { AnimateLayoutChanges, useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'

import UITreeItem, { type UITreeItemProps } from './Item'

export interface UISortableTreeItemProps extends UITreeItemProps {
  id: UniqueIdentifier
}

const animateLayoutChanges: AnimateLayoutChanges = ({
  isSorting,
  wasDragging,
}) => (isSorting || wasDragging ? false : true)

export function SortableTreeItem({
  id,
  depth,
  isDisabled,
  ...props
}: UISortableTreeItemProps) {
  const {
    attributes,
    isDragging,
    isSorting,
    listeners,
    setDraggableNodeRef,
    setDroppableNodeRef,
    transform,
    transition,
  } = useSortable({
    id,
    animateLayoutChanges,
  })
  const style: CSSProperties = {
    transform: CSS.Translate.toString(transform),
    transition,
  }

  return (
    <UITreeItem
      ref={setDraggableNodeRef}
      wrapperRef={setDroppableNodeRef}
      style={style}
      depth={depth}
      isDragging={isDragging}
      disableInteraction={isSorting}
      isDisabled={isDisabled}
      handleProps={{
        ...attributes,
        ...listeners,
      }}
      {...props}
    />
  )
}

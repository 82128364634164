import {
  DocsumDocumentType,
  DocumentTypeCreate,
  DocumentTypePatchRequest,
  Playbook,
} from '@/models/api'
import { API_DOCUMENT_TYPES_ROUTE_PATH } from '@/constants/api'

import BaseApi from './base'
import { apiClient, type ApiRequestConfig, getHeaders } from './client'

export class DocumentTypesApi extends BaseApi {
  constructor() {
    super()
    this.resourcePath = API_DOCUMENT_TYPES_ROUTE_PATH
  }

  async getAll({ signal, ...configProps }: ApiRequestConfig = {}) {
    return apiClient.getAll<DocsumDocumentType[]>(this.resourcePath, {
      signal,
      ...getHeaders(configProps),
    })
  }

  async create(payload: DocumentTypeCreate) {
    return apiClient.post<DocsumDocumentType>(this.resourcePath, payload)
  }

  async patch({ id, changes }: DocumentTypePatchRequest) {
    return apiClient.patch<DocsumDocumentType>(
      this.resourcePath,
      id.toString(),
      changes,
    )
  }

  async archive(id: number) {
    return apiClient.delete(this.resourcePath, id.toString())
  }

  async getBySlug(
    slug: string,
    { signal, ...configProps }: ApiRequestConfig = {},
  ) {
    return apiClient.get<DocsumDocumentType>(this.resourcePath, slug, {
      signal,
      ...getHeaders(configProps),
    })
  }

  async getPlaybooks(
    id: number,
    { signal, ...configProps }: ApiRequestConfig = {},
  ) {
    return apiClient.get<Playbook[]>(this.resourcePath, `${id}/playbooks`, {
      signal,
      ...getHeaders(configProps),
    })
  }
}

const documentTypesApi = new DocumentTypesApi()

export default documentTypesApi

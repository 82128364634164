import { Button, ButtonProps } from '@nextui-org/button'
import React, { forwardRef } from 'react'

import { cn } from '@/utils'

// hint: to remove outline on focus use: data-[focus-visible=true]:outline-none

export type UIButtonProps = {
  alterClassNameByParent?: boolean
} & ButtonProps

const UIButton = forwardRef<HTMLButtonElement | null, UIButtonProps>(
  (
    { alterClassNameByParent, children, className, ...props }: UIButtonProps,
    ref,
  ) => {
    return (
      <Button
        ref={ref}
        className={cn(
          alterClassNameByParent ? '' : 'rounded-small',
          className,
          props.color === 'primary' &&
            props.variant === 'flat' &&
            'text-primary-700',
          'data-[focus-visible=true]:outline-0 data-[focus-visible=true]:outline-offset-0 data-[focus-visible=true]:ring-1 data-[focus-visible=true]:ring-focus data-[focus-visible=true]:ring-offset-0',
        )}
        {...props}
      >
        {children}
      </Button>
    )
  },
)

UIButton.displayName = 'UIButton'

export default UIButton

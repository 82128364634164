export const MOBILE_MEDIA_QUERY = '(max-width: 768px)'

export const APP_LOCAL_STORAGE_KEY_PREFIX = 'docsum-'

export const DEFAULT_THEME_MATCH_MEDIA = '(prefers-color-scheme: dark)'

export const SUCCESS_CONFIRMATION_DELAY_IN_MS = 1500

export const DEFAULT_USER_ACTIVITY_EVENTS = [
  'mousemove',
  'keydown',
  'wheel',
  'DOMMouseScroll',
  'mousewheel',
  'mousedown',
  'touchstart',
  'touchmove',
  'MSPointerDown',
  'MSPointerMove',
  'visibilitychange',
]

export const DEFAULT_IDLE_TIMEOUT_MINUTES = 30

export const DEFAULT_PROMPT_TIMEOUT_MINUTES = 1

export const IDLE_TIMEOUT_MS =
  (Number(process.env.NEXT_PUBLIC_APP_IDLE_TIMEOUT_MINUTES) ||
    DEFAULT_IDLE_TIMEOUT_MINUTES) *
  60 *
  1000

export const PROMPT_TIMEOUT_MS =
  (Number(process.env.NEXT_PUBLIC_APP_PROMPT_TIMEOUT_MINUTES) ||
    DEFAULT_PROMPT_TIMEOUT_MINUTES) *
  60 *
  1000

export const DEFAULT_RANDOM_LONG_TEXTS_LIST = [
  'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
  'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.',
  'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?',
  'Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?',
  'At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus. Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet ut et voluptates repudiandae sint et molestiae non recusandae. Itaque earum rerum hic tenetur a sapiente delectus, ut aut reiciendis voluptatibus maiores alias consequatur aut perferendis doloribus asperiores repellat.',
]

export const DOCUMENT_DETAILS_HEADER_PORTAL_ELEMENT_ID =
  'document-header-details-portal'

export const DEFAULT_CLOSE_SHEET_ANIMATION_DURATION_MS = 225

export const DEFAULT_AUTHENTICATED_PAGE = 'all-documents'

export const DEFAULT_CURRENCY_CODE = 'USD'

// Note: if user initiate the app in a fresh browser tab with a deep link (paste URL directly), the history length will be 2
export const DEEP_LINK_BLANK_HISTORY_LENGTH = 2

export const DEFAULT_INPUT_DEBOUNCE_MS = 400

export const DEFAULT_FORM_DEBOUNCE_ON_CHANGE_MS = 1000

export const DEFAULT_FORM_RESET_DELAY_MS = 200

import {
  Announcements,
  closestCenter,
  DndContext,
  DragEndEvent,
  DragMoveEvent,
  DragOverEvent,
  DragOverlay,
  DragStartEvent,
  PointerSensor,
  UniqueIdentifier,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { useEffect, useMemo, useRef, useState } from 'react'
import { createPortal } from 'react-dom'

import { cn } from '@/utils'

import { FlattenedItem, TreeItems } from './types'
import {
  adjustTranslate,
  buildTree,
  dropAnimationConfig,
  flattenTree,
  getProjection,
  measuring,
  removeChildrenOf,
  removeItem,
  setProperty,
} from './utils'
import { SortableTreeItem } from './SortableItem'

export interface UITreeProps {
  collapsible?: boolean
  defaultItems: TreeItems
  indentationWidth?: number
  isEditable?: boolean
  isSortable?: boolean
  isCompact?: boolean
  currentPath?: string
  topContent?: React.ReactNode
  className?: string
  LinkComponent?: React.ElementType
  onChange?(items: TreeItems): void
}

const UITree = ({
  defaultItems,
  indentationWidth = 50,
  className,
  LinkComponent,
  currentPath,
  collapsible = false,
  isEditable = false,
  isCompact = false,
  isSortable = false,
  onChange,
}: UITreeProps) => {
  const [items, setItems] = useState(() => defaultItems)
  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null)
  const [overId, setOverId] = useState<UniqueIdentifier | null>(null)
  const [offsetLeft, setOffsetLeft] = useState(0)
  const [currentPosition, setCurrentPosition] = useState<{
    parentId: UniqueIdentifier | null
    overId: UniqueIdentifier
  } | null>(null)

  const flattenedItems = useMemo(() => {
    const flattenedTree = flattenTree(items)
    const collapsedItems = flattenedTree.reduce<string[]>(
      (acc, { children, collapsed, id }) =>
        collapsed && children.length ? [...acc, id as string] : acc,
      [],
    )

    return removeChildrenOf(
      flattenedTree,
      activeId ? [activeId, ...collapsedItems] : collapsedItems,
    )
  }, [activeId, items])

  const projected =
    activeId && overId
      ? getProjection(
          flattenedItems,
          activeId,
          overId,
          offsetLeft,
          indentationWidth,
        )
      : null

  const sensorContext = useRef({
    items: flattenedItems,
    offset: offsetLeft,
  })

  const sensors = useSensors(useSensor(PointerSensor))

  const sortedIds = useMemo(
    () => flattenedItems.map(({ id }) => id),
    [flattenedItems],
  )
  const activeItem = activeId
    ? flattenedItems.find(({ id }) => id === activeId)
    : null

  const announcements: Announcements = {
    onDragStart({ active }) {
      return `Picked up ${active.id}.`
    },
    onDragMove({ active, over }) {
      return getMovementAnnouncement('onDragMove', active.id, over?.id)
    },
    onDragOver({ active, over }) {
      return getMovementAnnouncement('onDragOver', active.id, over?.id)
    },
    onDragEnd({ active, over }) {
      return getMovementAnnouncement('onDragEnd', active.id, over?.id)
    },
    onDragCancel({ active }) {
      return `Moving was cancelled. ${active.id} was dropped in its original position.`
    },
  }

  function handleDragStart({ active: { id: activeId } }: DragStartEvent) {
    setActiveId(activeId)
    setOverId(activeId)

    const activeItem = flattenedItems.find(({ id }) => id === activeId)

    if (activeItem) {
      setCurrentPosition({
        parentId: activeItem.parentId,
        overId: activeId,
      })
    }

    document.body.style.setProperty('cursor', 'grabbing')
  }

  function handleDragMove({ delta }: DragMoveEvent) {
    setOffsetLeft(delta.x)
  }

  function handleDragOver({ over }: DragOverEvent) {
    setOverId(over?.id ?? null)
  }

  function handleDragEnd({ active, over }: DragEndEvent) {
    resetState()

    if (projected && over) {
      const { depth, parentId } = projected
      const clonedItems: FlattenedItem[] = [...flattenTree(items)]

      const overIndex = clonedItems.findIndex(({ id }) => id === over.id)
      const activeIndex = clonedItems.findIndex(({ id }) => id === active.id)
      const activeTreeItem = clonedItems[activeIndex]

      clonedItems[activeIndex] = { ...activeTreeItem, depth, parentId }

      const sortedItems = arrayMove(clonedItems, activeIndex, overIndex)
      const newItems = buildTree(sortedItems)

      setItems(newItems)
      onChange?.(newItems)
    }
  }

  function handleDragCancel() {
    resetState()
  }

  function resetState() {
    setOverId(null)
    setActiveId(null)
    setOffsetLeft(0)
    setCurrentPosition(null)

    document.body.style.setProperty('cursor', '')
  }

  function handleRemove(id: UniqueIdentifier) {
    setItems(items => {
      const newItems = removeItem(items, id)
      onChange?.(newItems)
      return newItems
    })
  }

  function handleCollapse(id: UniqueIdentifier) {
    setItems(items =>
      setProperty(items, id, 'collapsed', value => {
        return !value
      }),
    )
  }

  function handleRename(id: UniqueIdentifier, newLabel: string) {
    setItems(items => {
      const newItems = setProperty(items, id, 'label', () => newLabel)
      onChange?.(newItems)
      return newItems
    })
  }

  function getMovementAnnouncement(
    eventName: string,
    activeId: UniqueIdentifier,
    overId?: UniqueIdentifier,
  ) {
    if (overId && projected) {
      if (eventName !== 'onDragEnd') {
        if (
          currentPosition &&
          projected.parentId === currentPosition.parentId &&
          overId === currentPosition.overId
        ) {
          return
        } else {
          setCurrentPosition({
            parentId: projected.parentId,
            overId,
          })
        }
      }

      const clonedItems: FlattenedItem[] = JSON.parse(
        JSON.stringify(flattenTree(items)),
      )
      const overIndex = clonedItems.findIndex(({ id }) => id === overId)
      const activeIndex = clonedItems.findIndex(({ id }) => id === activeId)
      const sortedItems = arrayMove(clonedItems, activeIndex, overIndex)

      const previousItem = sortedItems[overIndex - 1]

      let announcement
      const movedVerb = eventName === 'onDragEnd' ? 'dropped' : 'moved'
      const nestedVerb = eventName === 'onDragEnd' ? 'dropped' : 'nested'

      if (!previousItem) {
        const nextItem = sortedItems[overIndex + 1]
        announcement = `${activeId} was ${movedVerb} before ${nextItem.id}.`
      } else {
        if (projected.depth > previousItem.depth) {
          announcement = `${activeId} was ${nestedVerb} under ${previousItem.id}.`
        } else {
          let previousSibling: FlattenedItem | undefined = previousItem
          while (previousSibling && projected.depth < previousSibling.depth) {
            const parentId: UniqueIdentifier | null = previousSibling.parentId
            previousSibling = sortedItems.find(({ id }) => id === parentId)
          }

          if (previousSibling) {
            announcement = `${activeId} was ${movedVerb} after ${previousSibling.id}.`
          }
        }
      }

      return announcement
    }

    return
  }

  useEffect(() => {
    sensorContext.current = {
      items: flattenedItems,
      offset: offsetLeft,
    }
  }, [flattenedItems, offsetLeft])

  useEffect(() => {
    setItems(defaultItems)
  }, [defaultItems])

  return (
    <ul className={cn('list-none', className)}>
      <DndContext
        accessibility={{ announcements }}
        sensors={sensors}
        collisionDetection={closestCenter}
        measuring={measuring}
        onDragStart={handleDragStart}
        onDragMove={handleDragMove}
        onDragOver={handleDragOver}
        onDragEnd={handleDragEnd}
        onDragCancel={handleDragCancel}
      >
        <SortableContext
          items={sortedIds}
          strategy={verticalListSortingStrategy}
        >
          {flattenedItems.map(
            ({
              id,
              label,
              type,
              children,
              collapsed,
              depth,
              href,
              Icon,
              ExpandedIcon,
              isDisabled,
            }) => (
              <SortableTreeItem
                key={id}
                id={id}
                label={label}
                depth={id === activeId && projected ? projected.depth : depth}
                indentationWidth={indentationWidth}
                isDisabled={isDisabled}
                isEditable={isEditable}
                isSortable={isSortable}
                href={href}
                currentPath={currentPath}
                Icon={Icon}
                ExpandedIcon={ExpandedIcon}
                isCompact={isCompact}
                showMenu={type === 'group'}
                as={href ? LinkComponent : undefined}
                collapsed={Boolean(collapsed && children.length)}
                onCollapse={
                  collapsible && children.length
                    ? () => handleCollapse(id)
                    : undefined
                }
                onRemove={isEditable ? () => handleRemove(id) : undefined}
                onRename={
                  isEditable && type === 'group'
                    ? (value: string) => handleRename(id, value)
                    : undefined
                }
              />
            ),
          )}
          {isCompact ? null : (
            <>
              {createPortal(
                <DragOverlay
                  dropAnimation={dropAnimationConfig}
                  modifiers={[adjustTranslate]}
                >
                  {activeId && activeItem ? (
                    <SortableTreeItem
                      id={activeId}
                      depth={activeItem.depth}
                      clone
                      label={activeItem.label}
                      Icon={activeItem.Icon}
                      ExpandedIcon={activeItem.ExpandedIcon}
                      indentationWidth={indentationWidth}
                    />
                  ) : null}
                </DragOverlay>,
                document.body,
              )}
            </>
          )}
        </SortableContext>
      </DndContext>
    </ul>
  )
}

UITree.displayName = 'Docsum.UITree'

export default UITree

import { AppSettings } from '@/models/api'

import BaseApi from './base'
import { apiClient, getHeaders, type ApiRequestConfig } from './client'

export class AppSettingsApi extends BaseApi {
  constructor() {
    super()
    this.resourcePath = 'settings'
  }

  async getAll({ signal, ...configProps }: ApiRequestConfig = {}) {
    return apiClient.get<AppSettings>(this.resourcePath, '', {
      signal,
      ...getHeaders(configProps),
    })
  }
}

const appSettingsApi = new AppSettingsApi()

export default appSettingsApi

import React, { useMemo } from 'react'
import { usePathname } from 'next/navigation'
import Link from 'next/link'

import { UITree } from '@/components/ui'
import { TreeItems, TreeItem } from '@/components/ui'
import {
  DashboardSquareAddIcon,
  BellIcon,
  AllBookmarkIcon,
  FieldsIcon,
} from '@/components/ui/icons'
import { NavSection } from '@/components/shared'
import { useFeatures } from '@/hooks'

import { NavSectionProps } from './types'

const initialItems: TreeItems = [
  {
    id: 'document-types',
    label: 'Document Types',
    type: 'item',
    href: '/document-types',
    Icon: AllBookmarkIcon,
    children: [],
  },
  {
    id: 'fields',
    label: 'Fields',
    type: 'item',
    href: '/fields',
    Icon: FieldsIcon,
    children: [],
  },
]

const ConfigureNavigation = ({ isCompact }: NavSectionProps) => {
  const pathname = usePathname()

  const { isAlertsEnabled } = useFeatures()

  const items = useMemo(() => {
    const alertsItem: TreeItem = {
      id: 'alerts',
      label: 'Alerts',
      type: 'item',
      href: '/alerts',
      Icon: BellIcon,
      children: [],
    }

    const baseItems = [...initialItems]
    if (isAlertsEnabled) {
      baseItems.push(alertsItem)
    }

    return baseItems
  }, [isAlertsEnabled])

  return (
    <NavSection
      title="Configure"
      isCompact={isCompact}
      CompactIcon={DashboardSquareAddIcon}
    >
      <UITree
        defaultItems={items}
        currentPath={pathname}
        LinkComponent={Link}
        isCompact={isCompact}
        collapsible
        indentationWidth={30}
      />
    </NavSection>
  )
}

export default ConfigureNavigation
